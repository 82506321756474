<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>{{ title }}</b-card-title>
        <b-card-text class="font-small-3">
          Total : {{ total }}
        </b-card-text>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body>

      <div
        v-for="(browser) in browserData"
        :key="browser.browserImg"
        class="browser-states"
      >
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-img
              :src="browser.browserImg"
              alt="browser img"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              {{ browser.name }}
            </h6>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{ browser.usage }}</span>
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      total: 0,
      chartData: [],
      chartClone: {},
      chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger],
      chartSeries: [54.4, 6.1, 14.6, 4.2, 8],
      browserData: [
      ],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
  created() {
    for (let i = 0; i < this.browserData.length; i += 1) {
      const chartClone = JSON.parse(JSON.stringify(this.chart))
      chartClone.options.colors[0] = this.chartColor[i]
      chartClone.series[0] = this.chartSeries[i]
      this.chartData.push(chartClone)
    }
  },
  watch: {
    data(val) {
      this.browserData = [
        {
          browserImg: require('@/assets/images/icons/google-chrome.png'),
          name: 'Sendinblue',
          usage: val.pingSendinblue,
        },
        {
          browserImg: require('@/assets/images/icons/mozila-firefox.png'),
          name: 'Webinar',
          usage: val.pingWebinar,
        },
        {
          browserImg: require('@/assets/images/icons/apple-safari.png'),
          name: 'Lemlist OB (e-mail Linkedin)',
          usage: val.pingLemlistOb,
        },
        {
          browserImg: require('@/assets/images/icons/internet-explorer.png'),
          name: 'Lemlist MOB (e-mail Insta)',
          usage: val.pingLemlistMOb,
        },
        {
          browserImg: require('@/assets/images/icons/opera.png'),
          name: 'Parrainage',
          usage: val.pingParrainage,
        },
        {
          browserImg: require('@/assets/images/icons/opera.png'),
          name: 'Fb Ads',
          usage: val.pingFbAds,
        },
        {
          browserImg: require('@/assets/images/icons/opera.png'),
          name: 'Linkedin',
          usage: val.pingLinkedin,
        },
      ]
      this.total = val.pingSendinblue + val.pingLinkedin + val.pingFbAds + val.pingParrainage + val.pingLemlistMOb + val.pingLemlistOb + val.pingWebinar
    },
  },
}
</script>
