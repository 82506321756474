<template>
  <b-card
    v-if="data"
    no-body
  >

    <b-card-body>
      <b-row>
        <b-col
          sm="3"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h2 class="font-large-1 font-weight-bolder mt-2 mb-0">
            CA : {{ Math.round(data.ca) }}€ HT
          </h2>
          <small
            v-if="data.evol_ca > 0"
            style="color:green;"
          >+ {{ Math.round(data.evol_ca) }}%</small>
          <small
            v-else
            style="color:red;"
          >{{ Math.round(data.evol_ca) }}%</small>
        </b-col>

        <!-- chart -->
        <b-col
          sm="5"
          class="d-flex justify-content-center"
        >

          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptions"
            :series="[Math.round(data.ca/6000*100)]"
          />
        </b-col>
        <b-col
          sm="4"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h2 class="font-large-1 font-weight-bolder mt-2 mb-0">
            Clients : {{ Math.round(data.clients) }}
          </h2>
        </b-col>
        <!--/ chart -->
      </b-row>

      <!-- chart info -->
      <div class="d-flex justify-content-between mt-4">
        <div class="text-center">
          <b-card-text class="mb-50">
            Nouveaux clients ce mois
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ data.nouveaux_clients }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            Clients perdus ce mois
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ data.clients_perdus }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            Clients connectés ce mois
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ data.nb_clients_co }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Objectif de CA atteint'],
        },
      },
    }
  },
}
</script>
